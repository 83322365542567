<template>
  <v-app class="app-background" dark>
    <v-container fluid class="pa-0 d-flex flex-column min-vh-100">
      <!-- 顶部导航栏 -->
      <v-app-bar elevation="2">

        <v-btn icon @click="goToAviator" class="back-btn" color="#00FF00" elevation="0">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title class="text-h6 font-weight-bold golden-text"
          style="white-space: nowrap; position: absolute; left: 50%; transform: translateX(-50%);">
          98Group's Websites
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-app-bar>

      <!-- 主要内容区域 -->
      <v-container style="margin-top: 60px;">
        <!-- 顶部横幅 -->
        <v-card class="banner-card mb-8" elevation="10">
          <v-img :src="require('@/assets/Banner.png')" width="100%" height="auto" contain
            @click="openPartnerLink('https://t.me/addlist/MlcGMAUaE081MTk1')">
          </v-img>
        </v-card>

        <!-- 合作伙伴卡片网格 -->
        <v-container>
          <v-row justify="center">
            <transition-group appear name="card">
              <v-col v-for="partner in partners" :key="partner.name" cols="6" sm="6" md="6" lg="4" class="mb-4 px-2"
                style="max-width: 450px">
                <div>
                  <v-img :src="partner.cardImage" width="100%" height="auto" @click="openPartnerLink(partner.link)"
                    style="cursor: pointer;"></v-img>
                </div>
              </v-col>
            </transition-group>
          </v-row>
        </v-container>
      </v-container>
      <!-- 修改 footer 部分 -->
      <v-spacer></v-spacer>
      <v-footer class="footer-section pa-0" color="black">
        <v-img :src="footerImage" width="100%" class="footer-image" :aspect-ratio="16 / 9" contain></v-img>
      </v-footer>
    </v-container>
  </v-app>
</template>

<script>
import footerImage from '@/assets/footer.jpg'

export default {
  name: 'App',
  data: () => ({
    partners: [
      {
        name: 'Aviator98',
        cardImage: require('@/assets/partners/aviator89.png'),
        link: 'https://aviator98.com/RF98GRPAVT'
      },
      {
        name: 'Spacex98',
        cardImage: require('@/assets/partners/spacex98.png'),
        link: 'https://spacex98.com/RF98GRPSPX'
      },
      {
        name: 'XBOX98',
        cardImage: require('@/assets/partners/xbox98.png'),
        link: 'https://xbox98.com/RF98GRPXBX'
      },
      {
        name: 'IRONMAN98',
        cardImage: require('@/assets/partners/ironman98.png'),
        link: 'https://ironman98.com/RF98GRPIRM'
      },
    ],
    footerImage: footerImage,
  }),
  methods: {
    goToAviator() {
      window.location.href = 'https://aviator98.com/';
    },
    openPartnerLink(link) {
      window.open(link, '_blank');
    }
  },
}
</script>
<style src="@/assets/styles/main.css"></style>